<template>
  <v-card class="pa-4" flat>
    <v-row v-if="$vuetify.breakpoint.xsOnly" justify="center" no-gutters>
      <FilterSchoolYear
        v-if="checkPrivilages('MSY01')"
        :triggerChange="getSchoolYearFilter"
        withInitial
        @handler="getFilter"
      />
      <v-btn
        v-if="checkPrivilages('MSY02')"
        class="caption gradient-primary mr-3"
        dark
        block
        depressed
        @click="schoolYearModalHandler()"
      >
        {{ $t("school_year.header.school_year") }}
        <v-icon class="ml-2" small>mdi-plus</v-icon>
      </v-btn>
      <v-btn
        v-if="checkPrivilages('MSY03')"
        class="caption gradient-primary mr-3"
        dark
        block
        depressed
        @click="viewSchoolYearModal = true"
      >
        {{ $t("app.view") }} {{ $t("school_year.header.school_year") }}
        <v-icon class="ml-2" small>mdi-eye-outline</v-icon>
      </v-btn>
    </v-row>

    <v-row v-else no-gutters>
      <FilterSchoolYear
        v-if="checkPrivilages('MSY01')"
        :triggerChange="getSchoolYearFilter"
        withInitial
        @handler="getFilter"
      />
      <v-spacer></v-spacer>
      <v-btn
        v-if="checkPrivilages('MSY02')"
        class="caption gradient-primary mr-3"
        dark
        depressed
        @click="schoolYearModalHandler()"
      >
        {{ $t("school_year.header.school_year") }}
        <v-icon class="ml-2" small>mdi-plus</v-icon>
      </v-btn>
      <v-btn
        v-if="checkPrivilages('MSY03')"
        class="caption gradient-primary mr-3"
        dark
        depressed
        @click="viewSchoolYearModal = true"
      >
        {{ $t("app.view") }} {{ $t("school_year.header.school_year") }}
        <v-icon class="ml-2" small>mdi-eye-outline</v-icon>
      </v-btn>
    </v-row>

    <v-divider v-if="checkPrivilages('MSY01')" class="my-4"></v-divider>

    <SchoolYear
      :isOpen="viewSchoolYearModal"
      :close="() => (this.viewSchoolYearModal = false)"
      :schoolYearList="schoolYearList"
      @triggerGetList="
        () => {
          this.getSchoolYear();
          this.getEventList();
          this.getSchoolYearFilter = true;
          this.$nextTick(() => {
            this.getSchoolYearFilter = false;
          });
        }
      "
    />

    <SchoolYearForm
      :isOpen="addSchoolYearModal"
      :edit="filterEvent"
      :close="
        param => {
          this.getSchoolYear();
          this.addSchoolYearModal = false;
        }
      "
    />

    <SchoolYearCalendar
      :gradeList="gradeList"
      :events="eventData"
      :loadingEvent="loadingEvent"
      :filter="filter"
      :schoolYearActive="schoolYearActive"
      :calendarRange="calendarRange"
      :checkPrivilages="checkPrivilages"
      @triggerGetList="() => this.getEventList()"
    />
  </v-card>
</template>

<script>
import SchoolYearCalendar from "./SchoolYearCalendar";
import SchoolYear from "./SchoolYearList";
import SchoolYearForm from "./SchoolYearForm";
import FilterSchoolYear from "../../components/filterSchooYear";
import moment from "moment/moment";
moment.locale("id");

let typingTimer;
const doneTypingInterval = 750;

import {
  get_school_year,
  get_event_list
  // get_master_color
} from "@/api/admin/academic/schoolYear";

import { get_school_year_list } from "@/api/admin/schoolClass";
import { checkPrivilages } from "@/utils/privilages";
import i18n from "@/i18n";

export default {
  metaInfo: {
    title: i18n.t("routes.school_year"),
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    FilterSchoolYear,
    SchoolYearCalendar,
    SchoolYear,
    SchoolYearForm
  },
  data() {
    return {
      loadingFilter: false,
      selectAllGrade: false,
      selectAllClass: false,
      loadingEvent: false,
      schoolYearList: [],
      gradeList: [],
      classList: [],
      calendarRange: {
        start: moment().format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD")
      },
      school_year_temp: null,
      schoolYearActive: null,
      filter: {
        school_year: [],
        grade: [],
        class: []
      },
      bottomMenu: false,
      viewSchoolYearModal: false,
      addSchoolYearModal: false,
      eventData: [],
      getSchoolYearFilter: false,
      filterEvent: {}
    };
  },
  async created() {
    await this.getSchoolYear();
    const getDataCondition =
      this.$store.getters.g_role_type === "STUDENT" ||
      this.$store.getters.g_role_type === "TEACHER";

    if (getDataCondition) {
      this.getData();
    }
    // get_master_color().then(res => {
    //   console.log(res);
    // });

    if (getDataCondition) {
      get_school_year_list().then(res => {
        if (res.data.code) {
          const data = res.data.data;
          const activeYear = data.find(r => r.status == 1);
          this.calendarRange.start = activeYear.start_date;
          this.calendarRange.end = activeYear.end_date;
        } else {
          this.$store.commit("CALL_SNACKBAR", {
            msg: res.data.message,
            color: "error"
          });
        }
      });
    }
  },
  methods: {
    checkPrivilages: name => checkPrivilages(name),
    schoolYearModalHandler() {
      this.filterEvent = {};
      this.$Progress.start();
      get_school_year().then(res => {
        if (res.data.code) {
          const data = res.data.data;
          this.addSchoolYearModal = true;
          this.$nextTick(() => (this.filterEvent = data));
        } else {
          this.$store.commit("CALL_SNACKBAR", {
            msg: res.data.message,
            color: "error"
          });
        }
        this.$Progress.finish();
      });
    },
    async getSchoolYear() {
      this.schoolYearList = await get_school_year_list().then(
        res => res.data.data
      );
    },
    async getFilter(res) {
      if (res) {
        this.filter = res.filter;
        this.schoolYearList = res.master.schoolYearList;
        this.gradeList = res.master.gradeList;
        this.classList = res.master.classList;

        switch (res.type) {
          case "school_year":
            this.calendarRange.start = res.data.start_date;
            this.calendarRange.end = res.data.end_date;
            this.schoolYearActive = res.data;
            break;
          default:
            break;
        }
        await this.getEventList();
      }
    },
    getData() {
      this.loadingEvent = true;
      get_event_list(this.filter).then(res => {
        if (res.data.code) {
          const events = res.data.data;
          const newEvents = [];
          events.map(evt => {
            newEvents.push({
              id: evt.id,
              name: evt.name,
              start: evt.start_date,
              end: evt.end_date,
              color: evt.background_color,
              text_color: evt.text_color,
              class: evt.class,
              type: evt.type,
              attendant: evt.attendant
            });
          });

          this.eventData = newEvents;
        }
        this.loadingEvent = false;
      });
    },
    getEventList() {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        if (this.filter.school_year.length > 0) {
          this.getData();
        }
      }, doneTypingInterval);
    }
  }
};
</script>
