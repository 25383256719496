<template>
  <v-row justify="center">
    <v-dialog
      v-model="isOpen"
      transition="scale-transition"
      persistent
      max-width="500px"
    >
      <v-card outlined>
        <v-card-title class="subtitle-1 font-weight-bold">{{
          idEdit ? $t("school_year.edit_event") : $t("school_year.add_event")
        }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
          <v-form ref="school_year_add_event" lazy-validation>
            <v-row class="mt-6">
              <v-col cols="12" class="mb-3">
                <div class="body-1 mb-3">
                  {{ $t("school_year.select_one") }}
                </div>
                <v-radio-group
                  v-model="form.type"
                  mandatory
                  row
                  class="mt-n2"
                  hide-details
                >
                  <v-radio :label="$t('app.event')" value="event"></v-radio>
                  <v-radio :label="$t('app.holiday')" value="holiday"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12">
                <div class="body-1 mb-2">
                  {{ $t("school_year.information") }}
                </div>
                <div
                  v-for="(desc, i) in form.information"
                  :key="i"
                  class="d-flex"
                >
                  <v-text-field
                    v-model="desc.name"
                    :rules="[v => !!v || $t('app.required')]"
                    :label="
                      form.type === 'event'
                        ? $t('school_year.event.add_event')
                        : $t('school_year.event.add_holiday')
                    "
                    class="mr-3"
                    outlined
                    dense
                  >
                    <template v-slot:append>
                      <v-icon
                        v-if="showHideBtn(i)"
                        color="primary"
                        @click="addDeleteDescription(null)"
                        >mdi-plus-box</v-icon
                      >
                      <v-icon
                        v-if="showHideBtn(i, 'delete')"
                        color="error"
                        @click="addDeleteDescription(i)"
                        >mdi-delete</v-icon
                      >
                    </template>
                  </v-text-field>
                  <ColorPicker
                    v-if="i < 5"
                    :initialValue="selectedColor"
                    @value="v => setColor(v, i)"
                  />
                </div>
              </v-col>
            </v-row>
            <v-divider class="mb-4"></v-divider>
            <v-row>
              <v-col cols="12" sm="6">
                <DatePicker
                  @getValue="
                    v => {
                      form.date_from = v;
                    }
                  "
                  :value="form.date_from"
                  :minDate="minDate"
                  :maxDate="calendarRange.end"
                  :label="$t('school_year.event.date_from')"
                  :rules="[v => !!v || $t('app.required')]"
                  notitle
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12" sm="6">
                <DatePicker
                  @getValue="v => (form.date_to = v)"
                  :value="form.date_to"
                  :minDate="form.date_from"
                  :maxDate="calendarRange.end"
                  :label="$t('school_year.event.date_to')"
                  :disabled="!form.date_from"
                  :rules="[v => !!v || $t('app.required')]"
                  notitle
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12" class="mb-6">
                <v-radio-group
                  v-model="form.to"
                  mandatory
                  row
                  class="mt-n2"
                  hide-details
                >
                  <v-radio :label="$t('app.student')" value="student"></v-radio>
                  <v-radio :label="$t('app.general')" value="general"></v-radio>
                  <v-radio
                    :label="$t('app.teacher_and_staff')"
                    value="staff"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col v-if="form.to == 'student'" cols="12" sm="6">
                <v-select
                  v-model="form.grade"
                  :rules="[v => v.length > 0 || $t('app.required')]"
                  :label="$t('school_year.event.select_grade')"
                  :items="gradeList"
                  item-value="id"
                  item-text="grade"
                  dense
                  outlined
                  multiple
                >
                  <template slot="prepend-item">
                    <v-list-item ripple @click="selectAllHandler('grade')">
                      <v-list-item-action>
                        <v-icon :color="form.grade.length > 0 ? 'primary' : ''">
                          {{ iconGrade }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t("app.select_all") }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <span v-if="index === 0" class="mr-2">{{
                      item.grade
                    }}</span>
                    <span v-if="index === 1" class="grey--text">
                      (+{{ form.grade.length - 1 }})
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col v-if="form.to == 'student'" cols="12" sm="6">
                <v-select
                  v-model="form.class_type"
                  :rules="[v => v.length > 0 || $t('app.required')]"
                  :disabled="form.grade.length == 0"
                  :label="$t('school_year.event.class_type')"
                  :loading="loadingGetList"
                  :items="classList"
                  item-value="id"
                  item-text="name"
                  outlined
                  dense
                  multiple
                >
                  <template slot="prepend-item">
                    <v-list-item ripple @click="selectAllHandler('class')">
                      <v-list-item-action>
                        <v-icon
                          :color="form.class_type.length > 0 ? 'primary' : ''"
                        >
                          {{ iconClass }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t("app.select_all") }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <span v-if="index === 0" class="mr-2">{{ item.name }}</span>
                    <span v-if="index === 1" class="grey--text">
                      (+{{ form.class_type.length - 1 }})
                    </span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="my-2 mr-3">
          <v-spacer></v-spacer>
          <v-btn outlined color="primary" @click="closeHandler('onlyCLose')">{{
            $t("app.cancel")
          }}</v-btn>
          <v-btn
            class="gradient-primary"
            :loading="loadingSave"
            depressed
            dark
            @click="save"
            >{{ $t("app.save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import DatePicker from "@/components/DatePicker";
import ColorPicker from "@/components/ColorPicker";
import i18n from "@/i18n";
import { onlyUnique } from "@/utils/validate";

import moment from "moment/moment";
moment.locale("id");
import { get_grade_list, get_class_list } from "@/api/admin/schoolClass";

import {
  add_event,
  edit_event,
  get_event_by_id
} from "@/api/admin/academic/schoolYear";

let typingTimer;
const doneTypingInterval = 750;

export default {
  props: {
    isOpen: Boolean,
    date: String,
    close: Function,
    idEdit: Number,
    calendarRange: Object,
    schoolYearActive: Object
  },
  components: {
    DatePicker,
    ColorPicker
  },
  data() {
    return {
      loadingGetList: false,
      loadingSave: false,
      gradeList: [],
      tempClassList: [],
      selectedColor: {},
      minDate: null,
      form: {
        date_from: "",
        date_to: "",
        grade: [],
        to: "",
        class_type: [],
        type: "",
        information: [
          { name: "", background_color: "#2ecc71", text_color: "#fff" }
        ]
      },
      classList: []
    };
  },
  computed: {
    selectAllClass() {
      return this.form.class_type.length === this.classList.length;
    },
    selectSomeClass() {
      return this.form.class_type.length > 0 && !this.selectAllClass;
    },
    selectAllGrade() {
      return this.form.grade.length === this.gradeList.length;
    },
    selectSomeGrade() {
      return this.form.grade.length > 0 && !this.selectAllGrade;
    },
    iconClass() {
      if (this.selectAllClass) return "mdi-checkbox-marked";
      if (this.selectSomeClass) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    iconGrade() {
      if (this.selectAllGrade) return "mdi-checkbox-marked";
      if (this.selectSomeGrade) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    }
  },
  watch: {
    async isOpen(val) {
      if (val) {
        this.minDate = this.schoolYearActive.start_date;
        const res = await get_grade_list();
        if (res.data) this.gradeList = res.data.data;

        this.form = {
          date_from: "",
          date_to: "",
          grade: [],
          to: "",
          class_type: [],
          type: "",
          information: [
            { name: "", background_color: "#2ecc71", text_color: "#fff" }
          ]
        };

        if (this.idEdit) {
          get_event_by_id(this.idEdit).then(res => {
            if (res.data.code) {
              const data = res.data.data;
              const event = {
                id: data.id,
                date_from: data.start_date,
                date_to: data.end_date,
                grade: data.class.map(r => r.grade),
                to: data.attendant,
                type: data.type,
                class_type: [],
                information: [
                  {
                    name: data.name,
                    background_color: data.background_color,
                    text_color: data.text_color
                  }
                ]
              };
              event.grade = event.grade.filter(onlyUnique);
              this.selectedColor = {
                background_color: event.information[0].background_color,
                text_color: event.information[0].text_color
              };
              this.tempClassList = data.class.map(r => r.id);
              this.form = event;
              this.getClassList();
            }
          });
        }
      } else this.$refs.school_year_add_event.reset();
    },
    "form.grade"(newVal) {
      if (newVal) this.getClassList();
    }
  },
  methods: {
    selectAllHandler(type) {
      switch (type) {
        case "grade":
          if (this.selectAllGrade) {
            this.form.grade = [];
          } else {
            this.form.grade = this.gradeList.map(r => r.id);
          }
          break;
        default:
          if (this.selectAllClass) {
            this.form.class_type = [];
          } else {
            this.form.class_type = this.classList.map(r => r.id);
          }
          break;
      }
    },
    getClassList() {
      if (this.form.grade) {
        this.classList = [];
        this.form.class_type = [];
        this.loadingGetList = true;

        clearTimeout(typingTimer);
        typingTimer = setTimeout(() => {
          get_class_list({
            school_year: this.schoolYearActive
              ? [this.schoolYearActive.id]
              : [],
            grade: this.form.grade
          }).then(res => {
            if (res.data.code) this.classList = res.data.data;
            this.loadingGetList = false;

            if (this.idEdit) this.form.class_type = this.tempClassList;
          });
        }, doneTypingInterval);
      }
    },
    save() {
      if (this.$refs.school_year_add_event.validate()) {
        const data = this.form;
        this.loadingSave = true;
        const newForm = {
          date_from: data.date_from,
          date_to: data.date_to,
          grade: data.grade,
          class: data.class_type,
          type: data.type,
          to: data.to,
          information: data.information
        };

        if (this.idEdit) {
          edit_event({ ...newForm, id: this.idEdit })
            .then(res => {
              if (res.data.code) {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: i18n.t("school_year.response.success_edit_event"),
                  color: "success"
                });
                this.closeHandler();
              } else {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: res.data.message,
                  color: "error"
                });
              }
              this.loadingSave = false;
            })
            .catch(() => {
              this.loadingSave = false;
            });
        } else {
          add_event(newForm)
            .then(res => {
              if (res.data.code) {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: i18n.t("school_year.response.success_add_event"),
                  color: "success"
                });
                this.closeHandler();
              } else {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: res.data.message,
                  color: "error"
                });
              }
              this.loadingSave = false;
            })
            .catch(() => {
              this.loadingSave = false;
            });
        }
      }
    },
    closeHandler(onlyCLose) {
      this.form = {
        date_from: "",
        date_to: "",
        grade: "",
        class_type: [],
        type: "",
        to: "",
        information: [
          { name: "", background_color: "#2ecc71", text_color: "#fff" }
        ]
      };

      this.$nextTick(() => {
        this.close(onlyCLose);
      });
    },
    addDeleteDescription(index) {
      if (index == null) {
        if (this.form.information.length < 5) {
          this.form.information.push({
            name: "",
            background_color: "#2ecc71",
            text_color: "#fff"
          });
        }
      } else {
        this.form.information.splice(index, 1);
      }
    },
    showHideBtn(i, del) {
      const des = this.form.information;
      if (del) {
        if (i == 4) return true;
        return des.length > 0 && des.length != 1;
      } else {
        return des.length < 5 && i == des.length - 1;
      }
    },
    setColor(v, i) {
      this.form.information[i].background_color = v.color;
      this.form.information[i].text_color = v.text;
    }
  }
};
</script>

<style></style>
