<template>
  <v-row justify="center">
    <v-dialog v-model="isOpen" persistent width="800">
      <v-card>
        <v-card-title>
          {{ $t("school_year.school_year_list") }}
          <v-spacer></v-spacer>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>
        <v-divider class="mb-4"></v-divider>
        <v-card-text>
          <v-data-table
            :headers="tableHeaders"
            :items="dataTable"
            :sort-desc="true"
            :items-per-page="5"
            :item-class="r => (r.status == 1 ? 'primary white--text' : '')"
            fixed-header
            class="elevation-0"
          >
            <template v-slot:item.start_date="{ item }">{{
              item.start_date | dateFormat
            }}</template>
            <template v-slot:item.end_date="{ item }">{{
              item.end_date | dateFormat
            }}</template>
            <template v-slot:item.action="{ item }">
              <div v-if="item.status === 2">
                <v-btn
                  icon
                  small
                  dark
                  class="primary mr-1"
                  @click="editSchoolYear(item)"
                >
                  <v-icon small>mdi-pencil-box-outline</v-icon>
                </v-btn>
                <v-btn
                  icon
                  small
                  dark
                  class="primary"
                  @click="deleteSchoolYearItem(item)"
                >
                  <v-icon small>mdi-delete-forever</v-icon>
                </v-btn>
              </div>
              <div v-else-if="item.status === 1">
                <v-btn
                  icon
                  small
                  dark
                  class="primary mr-1"
                  @click="editSchoolYear(item)"
                >
                  <v-icon small>mdi-pencil-box-outline</v-icon>
                </v-btn>
                <v-btn icon small disabled>
                  <v-icon small>mdi-delete-forever</v-icon>
                </v-btn>
              </div>
              <div v-else-if="item.status === 0">
                <v-btn icon small disabled class="mr-1">
                  <v-icon small>mdi-pencil-box-outline</v-icon>
                </v-btn>
                <v-btn icon small disabled>
                  <v-icon small>mdi-delete-forever</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>

      <SchoolYearForm
        :isOpen="editSchoolYearModal"
        :close="
          param => {
            this.editSchoolYearModal = false;
            if (param) this.$emit('triggerGetList', true);
          }
        "
        :edit="dataSchoolYear"
      />

      <ModalConfirm
        :isOpen="confirmDelete"
        :close="() => (this.confirmDelete = false)"
        :content="
          lastYearId
            ? $t('app.confirm_delete')
            : $t('school_year.confirm_delete_not_lastid')
        "
        :onlyCancel="!lastYearId"
        :save="deleteSchoolYear"
      />
    </v-dialog>
  </v-row>
</template>

<script>
import i18n from "@/i18n";
import moment from "moment/moment";
moment.locale("id");

import {
  delete_school_year,
  get_school_year_by_id
} from "@/api/admin/academic/schoolYear";
import SchoolYearForm from "./SchoolYearForm";
import ModalConfirm from "@/components/ModalConfirm";

export default {
  props: {
    isOpen: Boolean,
    close: Function,
    schoolYearList: Array
  },
  components: { SchoolYearForm, ModalConfirm },
  filters: {
    dateFormat: value => {
      if (!value) return "";
      return moment(value).format("DD MMM YYYY");
    }
  },
  watch: {
    schoolYearList(newVal) {
      this.dataTable = newVal;
    }
  },
  data() {
    return {
      confirmDelete: false,
      deleteData: {},
      lastYearId: false,
      editSchoolYearModal: false,
      dataSchoolYear: null,
      tableHeaders: [
        {
          text: i18n.t("school_year.table_header.start_year"),
          value: "start_year"
        },
        {
          text: i18n.t("school_year.table_header.end_year"),
          value: "end_year"
        },
        {
          text: i18n.t("school_year.table_header.semester"),
          value: "semester"
        },
        {
          text: i18n.t("school_year.table_header.start_date"),
          value: "start_date"
        },
        {
          text: i18n.t("school_year.table_header.end_date"),
          value: "end_date"
        },
        {
          text: i18n.t("school_year.table_header.action"),
          sortable: false,
          align: "center",
          value: "action",
          width: 125
        }
      ],
      dataTable: []
    };
  },
  methods: {
    editSchoolYear(item) {
      this.dataSchoolYear = {};
      this.$Progress.start();
      get_school_year_by_id(item.id).then(res => {
        if (res.data.code) {
          this.editSchoolYearModal = true;
          this.$nextTick(() => (this.dataSchoolYear = res.data.data));
        } else {
          this.$store.commit("CALL_SNACKBAR", {
            msg: res.data.message,
            color: "error"
          });
        }
        this.$Progress.finish();
      });
    },
    deleteSchoolYearItem(item) {
      this.lastYearId = false;
      this.deleteData = item;
      this.confirmDelete = true;
      const getLastId = this.dataTable.sort();
      if (item.id == this.dataTable[getLastId.length - 1].id) {
        this.lastYearId = true;
      }
    },
    deleteSchoolYear() {
      if (this.lastYearId) {
        delete_school_year(this.deleteData.id).then(res => {
          if (res.data.code) {
            this.$store.commit("CALL_SNACKBAR", {
              msg: this.$i18n.t("school_year.response.success_delete"),
              color: "success"
            });
            this.confirmDelete = false;
            this.$emit("triggerGetList", true);
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
          }
        });
      } else {
        this.confirmDelete = false;
      }
    }
  }
};
</script>
