<template>
  <v-dialog
    v-model="isOpen"
    persistent
    :width="$vuetify.breakpoint.xsOnly ? '100%' : '60%'"
  >
    <v-card width="100%">
      <v-card-title>{{
        id ? $t("school_year.title3") : $t("school_year.title2")
      }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-4 pb-0">
        <v-form ref="school_year_form" lazy-validation>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="year"
                :label="$t('school_year.select_school_year')"
                readonly
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="semester.semester"
                :label="$t('school_year.semester')"
                readonly
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <DatePicker
                @getValue="v => (semester.start_date = v)"
                :value="semester.start_date"
                :minDate="min_date"
                :maxDate="max_date"
                :label="$t('school_year.start_semester')"
                notitle
                outlined
                dense
              />
            </v-col>
            <v-col cols="12" sm="6">
              <DatePicker
                @getValue="v => (semester.end_date = v)"
                :value="semester.end_date"
                :minDate="min_date"
                :maxDate="max_date"
                :disabled="semester.start_date ? false : true"
                :label="$t('school_year.end_semester')"
                notitle
                outlined
                dense
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider class="mb-4"></v-divider>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-spacer></v-spacer>
            <div class="float-right">
              <v-btn text class="mr-4" @click="() => closeHandler(null)">{{
                $t("app.back")
              }}</v-btn>
              <v-btn
                :loading="loading"
                class="primary"
                depressed
                @click="saveForm"
                >{{ id ? $t("app.edit") : $t("app.save") }}</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DatePicker from "@/components/DatePicker";
import {
  add_school_year,
  edit_school_year
} from "@/api/admin/academic/schoolYear";

export default {
  props: {
    isOpen: Boolean,
    close: Function,
    edit: Object
  },
  components: { DatePicker },
  data() {
    return {
      id: null,
      loading: false,
      year: "",
      min_date: "",
      max_date: "",
      semester: {
        start_year: "",
        end_year: "",
        semester: "",
        start_date: "",
        end_date: ""
      }
    };
  },
  watch: {
    edit(data) {
      this.semester = {
        start_year: data.start_year,
        end_year: data.end_year,
        semester: data.semester,
        start_date: data.start_date,
        end_date: data.end_date
      };

      this.year = data.start_year + " / " + data.end_year;
      this.id = data.id;

      this.min_date = data.start_date;
      this.max_date = data.end_date;
      if (data.id) {
        this.min_date = data.min_date;
        this.max_date = data.max_date;
      }
    }
  },
  methods: {
    closeHandler(param) {
      this.$refs.school_year_form.reset();
      this.close(param);
    },
    setHoliday(param) {
      const sem = this.semester;
      const isExist = sem.holiday.filter(v => v === param).length > 0;

      if (isExist) {
        sem.holiday = sem.holiday.filter(v => v !== param);
      } else {
        sem.holiday.push(param);
      }
    },
    saveForm() {
      if (this.$refs.school_year_form.validate()) {
        this.loading = true;
        if (this.id) {
          const body = { ...this.semester, id: this.id };
          edit_school_year(body)
            .then(res => {
              if (res.data.code) {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: this.$i18n.t("school_year.response.success_edit"),
                  color: "success"
                });
                this.closeHandler("save");
              } else {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: res.data.message,
                  color: "error"
                });
              }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          add_school_year(this.semester)
            .then(res => {
              if (res.data.code) {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: this.$i18n.t("school_year.response.success"),
                  color: "success"
                });
                this.closeHandler("save");
              } else {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: res.data.message,
                  color: "error"
                });
              }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        }
      }
    }
  }
};
</script>
