<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="300">
      <template v-slot:activator="{ on }">
        <div
          v-on="on"
          :style="{ backgroundColor: selectedColor.color }"
          depressed
          class="selected-color"
        ></div>
      </template>

      <v-card>
        <v-card-title class="primary white--text">
          {{ $t("school_year.event.select_color") }}
        </v-card-title>
        <v-card-text>
          <div class="mt-4 d-flex flex-wrap justify-space-around">
            <div
              v-for="color in colors"
              :key="color.id"
              :style="{ backgroundColor: color.color, color: color.text }"
              class="selected-color ma-2"
              @click="selectColor(color)"
            ></div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: { initialValue: Object },
  data() {
    return {
      dialog: false,
      selectedColor: {
        id: 1,
        color: "#2ecc71",
        text: "#fff"
      },
      colors: [
        { id: 1, color: "#2ecc71", text: "#fff" },
        { id: 2, color: "#f39c12", text: "#fff" },
        { id: 3, color: "#2c3e50", text: "#fff" },
        { id: 4, color: "#95a5a6", text: "#fff" },
        { id: 5, color: "#ecf0f1", text: "#000" },
        { id: 6, color: "#c0392b", text: "#fff" },
        { id: 7, color: "#d35400", text: "#fff" },
        { id: 8, color: "#2980b9", text: "#fff" }
      ]
    };
  },
  watch: {
    initialValue(n) {
      if (n) {
        this.selectedColor = this.colors.find(
          r => r.color == n.background_color
        );
      }
    }
  },
  methods: {
    selectColor(color) {
      this.selectedColor = color;
      this.dialog = false;
      this.$emit("value", color);
    }
  }
};
</script>

<style lang="scss">
.selected-color {
  border-radius: 7px;
  border: 1px solid lightgray;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
</style>
