<template>
  <div>
    <v-row v-if="loadingEvent" no-gutters justify="center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-row>
    <v-card v-else outlined class="mt-6">
      <v-card-title
        class="title py-6"
        :style="!g_darkmode && 'background: #eff9fd'"
        primary-title
      >
        <v-row justify="center" class="mx-1">
          <div
            class="d-flex align-center"
            :class="$vuetify.breakpoint.xsOnly && 'mb-2'"
          >
            <v-btn icon x-small color="primary" @click="prev">
              <v-icon dark>mdi-chevron-left</v-icon>
            </v-btn>
            <span class="mx-3 font-weight-bold">{{ selected_month }}</span>
            <v-btn icon x-small color="primary" @click="next">
              <v-icon dark>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
          <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
          <v-btn
            v-if="checkPrivilages('MSY04')"
            :disabled="!schoolYearActive"
            class="white--text caption"
            color="primary"
            outlined
            @click="modalFormEvent"
          >
            {{ $t("school_year.add_event") }}
            <v-icon class="ml-2">mdi-calendar-check</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-sheet>
          <v-calendar
            ref="calendarEvent"
            v-model="today"
            :now="today"
            :events="events"
            :event-color="getEventColor"
            :start="calendarRange.start"
            :end="calendarRange.end"
            :locale="g_language"
            color="primary"
            class="caption mt-4"
            @click:date="showEventByDate"
            @click:event="showEvent"
            @change="getChange"
          >
            <!-- <template v-slot:day="{ date }">
            <template v-if="getEvent(date)" class="primary">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-sheet
                    v-bind="attrs"
                    v-on="on"
                    color="black"
                    dark
                    class="rounded text-center text-truncate"
                  >
                    Libur kelas I A
                  </v-sheet>
                </template>
                <span>Libur kelas I A ajksdhkjashd</span>
              </v-tooltip>
            </template>
          </template> -->
          </v-calendar>
        </v-sheet>
      </v-card-text>
    </v-card>

    <v-dialog v-if="selectedEvent" v-model="showModalEvent" width="500">
      <v-card>
        <v-card-title
          :style="{
            backgroundColor: selectedEvent.event[0].background_color,
            color: selectedEvent.event[0].text_color
          }"
          primary-title
        >
          <v-row class="sub-title">
            <span
              v-if="
                selectedEvent.event[0].start_date !==
                  selectedEvent.event[0].end_date
              "
            >
              {{ selectedEvent.event[0].start_date | dateFormat }}
              - {{ selectedEvent.event[0].end_date | dateFormat }}
            </span>
            <span v-else>
              {{ selectedEvent.event[0].start_date | dateFormat }}
            </span>
            <v-spacer></v-spacer>
            <v-btn
              v-if="checkPrivilages('MSY05')"
              icon
              @click="editEvent(selectedEvent.event[0].id)"
              class="mr-2"
            >
              <v-icon :color="selectedEvent.event[0].text_color">
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-btn
              v-if="checkPrivilages('MSY06')"
              icon
              @click="
                () => {
                  this.idDelete = selectedEvent.event[0].id;
                  this.confirmDelete = true;
                }
              "
            >
              <v-icon :color="selectedEvent.event[0].text_color">
                mdi-delete
              </v-icon>
            </v-btn>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-3">
          <v-sheet
            v-for="(evt, i) in selectedEvent.event"
            :key="i"
            class="d-block font-weight-bold grey lighten-5 black--text mb-2 pa-4 rounded"
            :class="g_darkmode ? 'grey lighten-1' : ''"
          >
            {{ evt.name }} {{ evt.class_name && $t("app.class") }}
            {{ evt.class_name }}
          </v-sheet>

          <v-row
            class="caption font-weight-bold mt-2"
            align="center"
            no-gutters
          >
            <v-btn small text @click="showModalEvent = false">{{
              $t("app.back")
            }}</v-btn>
            <v-spacer></v-spacer>
            {{ $t(`app.${typeAttendant}`) }} {{ $t(`app.for`) }}
            {{
              eventAttendant == "staff" || eventAttendant == "teacher"
                ? $t(`app.teacher_and_staff`)
                : $t(`app.${eventAttendant}`)
            }}
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-if="selectedEvents" v-model="showModalEvents" width="500">
      <v-card>
        <v-card-title
          :style="{
            backgroundColor: selectedEvents[0].color,
            color: selectedEvents[0].text_color
          }"
          primary-title
        >
          {{ selectedEventsDate | dateFormat }}
          <v-spacer></v-spacer>
          <v-icon dark @click="showModalEvents = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-3">
          <v-sheet
            v-for="(evt, i) in selectedEvents"
            :key="i"
            class="d-block font-weight-bold grey lighten-5 black--text mb-2 pa-4 rounded"
            :class="g_darkmode ? 'grey lighten-1' : ''"
          >
            {{ evt.name }}
            <div class="caption">
              {{ $t(`app.${evt.type}`) }}
              {{ $t(`app.for`) }}
              {{ $t(`app.${evt.attendant}`) }}
            </div>
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-dialog>

    <SchoolYearAddEvent
      :isOpen="event_modal"
      :close="closeFormEvent"
      :date="event_date"
      :calendarRange="calendarRange"
      :schoolYearActive="schoolYear"
      :gradeList="gradeList"
      :idEdit="idEdit"
    />

    <ModalConfirm
      :isOpen="confirmDelete"
      :close="() => (confirmDelete = false)"
      :save="deleteEvents"
      :loading="loadingDelete"
    />
  </div>
</template>

<script>
import SchoolYearAddEvent from "./SchoolYearAddEvent";
import ModalConfirm from "@/components/ModalConfirm";
import i18n from "@/i18n";

import { mapGetters } from "vuex";
import { get_event_by_id, delete_event } from "@/api/admin/academic/schoolYear";

import moment from "moment/moment";

export default {
  props: [
    "events",
    "gradeList",
    "filter",
    "schoolYearActive",
    "calendarRange",
    "loadingEvent",
    "checkPrivilages"
  ],
  components: {
    SchoolYearAddEvent,
    ModalConfirm
  },
  filters: {
    dateFormat: value => {
      if (!value) return "";
      return moment(value).format("DD MMMM YYYY");
    }
  },
  data() {
    return {
      checkBtn: {
        prev: true,
        next: true
      },
      today: moment().format("YYYY-MM-DD"),
      selectedDate: moment().format("YYYY-MM-DD"),
      selected_month: moment().format("MMMM YYYY"),
      weekday: [0, 1, 2, 3, 4, 5, 6],
      event_date: "",
      event_modal: false,
      selectedEventsDate: null,
      selectedEvent: null,
      selectedEvents: null,
      showModalEvent: false,
      showModalEvents: false,
      idEdit: 0,
      idDelete: 0,
      confirmDelete: false,
      loadingDelete: false,
      disablePrev: false,
      disableNext: false,
      eventAttendant: "",
      typeAttendant: ""
    };
  },
  computed: {
    ...mapGetters(["g_language", "g_darkmode"]),
    schoolYear() {
      return this.schoolYearActive;
    }
  },
  watch: {
    events(newVal) {
      if (newVal) {
        this.selectedDate = moment().format("YYYY-MM-DD hh:mm:ss");
        this.today = moment().format("YYYY-MM-DD hh:mm:ss");
        this.selected_month = moment().format("MMMM YYYY");

        if (this.schoolYearActive) {
          if (this.schoolYearActive.status != 1) {
            if (this.calendarRange.start) {
              this.selectedDate = moment(this.calendarRange.start).format(
                "YYYY-MM-DD hh:mm:ss"
              );
              this.today = moment(this.calendarRange.start).format(
                "YYYY-MM-DD hh:mm:ss"
              );
              this.selected_month = moment(this.calendarRange.start).format(
                "MMMM YYYY"
              );
            }
          }
        }
      }
    }
  },
  mounted() {
    moment.locale(this.$store.getters.g_language);
  },
  methods: {
    prev() {
      this.disablePrev = false;
      if (this.$refs.calendarEvent) {
        const startDate = this.$refs.calendarEvent.parsedStart;
        if (startDate.date < this.$refs.calendarEvent.lastStart.date) {
          this.$refs.calendarEvent.prev();
        }
        // else {
        //   this.disablePrev = true;
        // }
      }
    },
    next() {
      this.disablePrev = false;
      if (this.$refs.calendarEvent) {
        const endDate = this.$refs.calendarEvent.parsedEnd;
        if (endDate.date > this.$refs.calendarEvent.lastEnd.date) {
          this.$refs.calendarEvent.next();
        }
        // else {
        //   this.disableNext = true;
        // }
      }
    },
    getEvent(date) {
      return (
        this.events.filter(e => date >= e.start && date <= e.end).length > 0
      );
    },
    getChange(e) {
      this.checkBtn.prev = e.start.date >= this.calendarRange.start;
      this.checkBtn.next = e.end.date <= this.calendarRange.start;
      this.selected_month = moment(e.start.date).format("MMMM YYYY");
      if (e.start.date <= this.calendarRange.start) {
        this.today = this.calendarRange.start;
      }
      if (e.end.date >= this.calendarRange.end) {
        this.today = this.calendarRange.end;
      }
    },
    getEventColor(e) {
      return e.color;
    },
    modalFormEvent(e) {
      this.idEdit = 0;
      this.event_date = e.date;
      this.event_modal = true;
    },
    closeFormEvent(onlyCLose) {
      this.event_modal = false;
      if (!onlyCLose) this.$emit("triggerGetList", true);
      this.showModalEvent = false;
    },
    showEvent(e) {
      this.$Progress.start();
      get_event_by_id(e.event.id)
        .then(res => {
          if (res.data.code) {
            const data = res.data.data;
            this.eventAttendant = data.attendant;
            this.typeAttendant = data.type;
            const event = [];
            if (data.class.length > 0) {
              data.class.map(e => {
                event.push({
                  id: data.id,
                  institution: data.institution,
                  name: data.name,
                  start_date: data.start_date,
                  end_date: data.end_date,
                  attendant: data.attendant,
                  background_color: data.background_color,
                  text_color: data.text_color,
                  type: data.type,
                  created_at: data.created_at,
                  updated_at: data.updated_at,
                  deleted_at: data.deleted_at,
                  class_name: e.name
                });
              });
            } else {
              event.push({
                id: data.id,
                institution: data.institution,
                name: data.name,
                start_date: data.start_date,
                end_date: data.end_date,
                attendant: data.attendant,
                background_color: data.background_color,
                text_color: data.text_color,
                type: data.type,
                created_at: data.created_at,
                updated_at: data.updated_at,
                deleted_at: data.deleted_at
              });
            }

            this.$nextTick(() => {
              this.selectedEvent = {
                event: event,
                day: e.day
              };
              this.showModalEvent = true;
              this.$Progress.finish();
            });
          }
        })
        .catch(() => this.$Progress.finish());
    },
    showEventByDate(e) {
      if (e && this.events.length > 0) {
        const events = this.events;
        const eventList = [];

        if (e.date >= this.end) {
          this.selectedDate = this.end;
        } else if (e.date <= this.start) {
          this.selectedDate = this.start;
        } else {
          this.selectedDate = e.date;
        }

        events.map(evt => {
          if (e.date >= evt.start && e.date <= evt.end) {
            eventList.push(evt);
          }
        });

        if (eventList.length > 0) {
          this.selectedEvents = eventList;
          this.selectedEventsDate = e.date;
          this.showModalEvents = true;
        }
      }
    },
    editEvent(id) {
      this.idEdit = id;
      this.event_modal = true;
    },
    deleteEvents() {
      this.loadingDelete = true;
      delete_event(this.idDelete).then(res => {
        if (res.data.code) {
          this.$store.commit("CALL_SNACKBAR", {
            msg: i18n.t("school_year.response.success_delete_event"),
            color: "success"
          });
          this.confirmDelete = false;
          this.showModalEvent = false;
          this.$emit("triggerGetList", true);
        } else {
          this.$store.commit("CALL_SNACKBAR", {
            msg: res.data.message,
            color: "error"
          });
        }
        this.loadingDelete = false;
      });
    }
  }
};
</script>

<style scoped>
.controls {
  position: relative;
}
</style>
