import request from "@/utils/request";

export function get_school_year() {
  return request({
    method: "POST",
    url: "school_year/suggest_next_semester"
  });
}

export function get_master_color() {
  return request({
    method: "POST",
    url: "master/list/color"
  });
}

export function get_school_year_by_id(id) {
  return request({
    method: "POST",
    url: "school_year/by_id",
    data: { id: id, is_edit: true }
  });
}

export function add_school_year(data) {
  return request({
    method: "POST",
    url: "school_year/create",
    data: data
  });
}

export function edit_school_year(data) {
  return request({
    method: "POST",
    url: "school_year/edit",
    data: data
  });
}

export function delete_school_year(id) {
  return request({
    method: "POST",
    url: "school_year/delete",
    data: { id: id }
  });
}

export function get_event_list(data) {
  return request({
    method: "POST",
    url: "event/list",
    data: data
  });
}

export function add_event(data) {
  return request({
    method: "POST",
    url: "event/create",
    data: data
  });
}

export function edit_event(data) {
  return request({
    method: "POST",
    url: "event/update",
    data: data
  });
}

export function get_event_by_id(id) {
  return request({
    method: "POST",
    url: "event/get_by_id",
    data: { id: id }
  });
}

export function delete_event(id) {
  return request({
    method: "POST",
    url: "event/delete",
    data: { id: id }
  });
}
